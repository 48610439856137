<h1 class="mat-display">
    <mat-icon class="vertical-align-middle size-45">explore</mat-icon> CVE Info (Online DB)
</h1>
<mat-dialog-content>

    <mat-form-field class="example-full-width" color="accent" appearance="outline">
        <mat-label>CVE</mat-label>
        <input matInput placeholder="e.g.: CVE-2017-0144" [formControl]="mycve">
    </mat-form-field>

    <div *ngIf="results">

        <div style="width: 100%;" fxLayout.xs="column" fxLayoutGap="10px" ngClass.gt-xs="ml-10">
            <div fxFlex.sm="0 1 calc(50%-10px)" fxFlex.md="0 1 calc(33%-10px)" fxFlex.gt-md="0 1 calc(25%-10px)" *ngIf="results?.Published" fxFlex><b>Published:</b><br>{{ results?.Published }}</div>
            <div fxFlex.sm="0 1 calc(50%-10px)" fxFlex.md="0 1 calc(33%-10px)" fxFlex.gt-md="0 1 calc(25%-10px)" *ngIf="results?.Modified" fxFlex><b>Modified:</b><br>{{ results?.Modified }}</div>
            <div fxFlex.sm="0 1 calc(50%-10px)" fxFlex.md="0 1 calc(33%-10px)" fxFlex.gt-md="0 1 calc(25%-10px)" *ngIf="results?.assigner" fxFlex><b>Assigner:</b><br>{{ results?.assigner }}</div>
        </div>
        
        <p *ngIf="results?.summary"><b>Summary:</b> {{ results?.summary }}</p>
        <p>
            <mat-chip-list>
                <mat-chip *ngIf="results?.cvss">CVSS: {{ results?.cvss }}</mat-chip>
                <mat-chip *ngIf="results?.cwe">{{ results?.cwe }}</mat-chip>

                <div *ngFor="let item of results?.references">
                    <a target="_blank" rel="noopener noreferrer" href="{{ item }}">
                        <mat-chip>{{ item }}</mat-chip>
                    </a>
                </div>

            </mat-chip-list>
        </p>




        <mat-form-field class="example-full-width" color="accent" appearance="outline">
            <mat-label>Source data (JSON):</mat-label>
            <textarea matInput>{{ results | json }}</textarea>
        </mat-form-field>

    </div>

    <div *ngIf="show">
        Please wait...<mat-progress-bar color="accent" mode="indeterminate"></mat-progress-bar>
    </div>
    {{err_msg}}
</mat-dialog-content>
<mat-dialog-actions align="start">
    <button mat-raised-button color="accent" (click)="searchCVE()">
        <mat-icon matSuffix>explore</mat-icon> Search CVE 
    </button>
    &nbsp;

    <div *ngIf="results">
        <button mat-raised-button color="accent" (click)="saveCVE()">
            <mat-icon matSuffix>save</mat-icon> Save & close
        </button>&nbsp;
    </div>

    <button mat-flat-button color="primary" mat-dialog-close>Cancel</button>
</mat-dialog-actions>